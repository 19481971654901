
export class Header {

	wrapper: HTMLElement;
	coverHeight: number;

	 lastScrollTop: number;

	constructor() {

		this.wrapper = document.querySelector('header');
		this.lastScrollTop = window.scrollY;

		// EFFETTO HIDE/SHOW SU DIREZIONE SCROLL
		window.addEventListener('scroll', (ev: any) => {
			const st = window.scrollY || document.documentElement.scrollTop;

			if ( st > 0 ) {
				document.body.classList.add('header-sticky');
				this.wrapper.classList.add('sticky');
			} else {
				document.body.classList.remove('header-sticky');
				this.wrapper.classList.remove('sticky');
			}

			if (st > this.lastScrollTop) {

				// downscroll code
				if ( window.scrollY > (window.innerHeight/4) ) {
					this.wrapper.classList.add('hide-out');
				}

			} else if (st < this.lastScrollTop) {
				// upscroll code
				this.wrapper.classList.remove('hide-out');
			}

			this.lastScrollTop = st <= 0 ? 0 : st;
		});
	}

}

