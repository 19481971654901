
// @ts-ignore
import * as Pristine from 'pristinejs';


export class SearchForm {

	static pristine: any;

	static pristineConfig = {
		classTo: 'search-box',
		errorClass: 'error',
		successClass: 'success',
		errorTextParent: 'field',
		errorTextTag: 'p',
		errorTextClass: 'error'
	};

	searchForm: HTMLFormElement;
	searchFormCta: HTMLButtonElement;

	constructor() {
		this.searchForm        = document.querySelector('.search-box');
		this.searchFormCta = this.searchForm.querySelector('button[type="submit"]');

		SearchForm.pristine = new Pristine(this.searchForm, SearchForm.pristineConfig, true);

		this.searchFormCta.addEventListener('click', (ev: MouseEvent) => {
			ev.preventDefault();

			const valid = SearchForm.pristine.validate();

			if (valid) {
				this.searchForm.submit();
			}
		});

	}

}