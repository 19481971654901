import { gsap } from 'gsap';

export class Menu {

	trigger: HTMLButtonElement;
	wrapper: HTMLElement;
	submenuTriggers: NodeListOf<HTMLElement>;

	constructor() {
		this.wrapper = document.querySelector('nav');
		this.trigger = document.querySelector('.menu-cta');

		this.submenuTriggers = document.querySelectorAll('.submenu-cta-wrapper');

		this.attachEventListeners();
	}


	attachEventListeners() {
		this.trigger.addEventListener('click', () => {
			this.toggleMenu();
		});

		if ( this.isTouchDevice() ) {
			this.submenuTriggers.forEach( (st: HTMLElement) => {
				st.addEventListener('click', () => {
					this.toggleSubMenu( st.querySelector('.toggle-submenu') );
				});
			});
		}
	}


	toggleMenu() {
		if ( this.trigger.classList.contains('active') ) {
			this.closeMenu();
		} else {
			this.openMenu();
		}
	}

	toggleSubMenu(trigger: HTMLElement) {
		if ( trigger.classList.contains('active') ) {
			this.closeSubmenu(trigger);
		} else {
			this.openSubmenu(trigger);
		}
	}


	openSubmenu(trigger: HTMLElement) {
		trigger.classList.add('active');
		gsap.to( '.' + trigger.dataset.submenu, { height: 'auto' });
	}

	closeSubmenu(trigger: HTMLElement) {
		trigger.classList.remove('active');
		gsap.to( '.' + trigger.dataset.submenu, { height: 0 });
	}


	closeMenu() {
		gsap.to( this.wrapper, { left: '100%', ease: "power1", duration: .3 });
		this.trigger.classList.remove('active');
		document.body.classList.remove('menu-open');

		this.submenuTriggers.forEach( (btn: HTMLButtonElement) => {
			this.closeSubmenu(btn);
		});
	}

	openMenu() {
		gsap.to( this.wrapper, { left: 0, ease: "power1", duration: .3 });
		this.trigger.classList.add('active');
		document.body.classList.add('menu-open');
		this.wrapper.scrollIntoView();
	}

	isTouchDevice() {
		// @ts-ignore
		return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
	}

}

