import { FetchHeaders } from "../../helpers/fetch-headers";
import { gsap } from "gsap";

import './newsletter-box.scss';

// @ts-ignore
import * as Pristine from 'pristinejs';

// @ts-ignore
declare var grecaptcha;


export class NewsletterBox {

	static pristineStart: any;
	static pristine: any;

	static pristineConfig = {
		classTo: 'field',
		errorClass: 'error',
		successClass: 'success',
		errorTextParent: 'field',
		errorTextTag: 'p',
		errorTextClass: 'error'
	};

	wrapper: HTMLElement;

	newsletterStartingForm: HTMLFormElement;
	newsletterStartingFormCta: HTMLButtonElement;
	newsletterStartingFormFields: NodeListOf<HTMLElement>;

	overlay: HTMLElement;
	modal: HTMLElement;
	modalForm: HTMLFormElement;
	modalFormCta: HTMLButtonElement;
	modalFormFields: NodeListOf<HTMLElement>;
	closeModal: HTMLButtonElement;

	successMessage: HTMLElement;
	errorMessage: HTMLElement;
	loader: HTMLElement;

	constructor() {
		this.wrapper                      = document.querySelector('.newsletter-box');
		this.newsletterStartingForm       = this.wrapper.querySelector('form');
		this.newsletterStartingFormCta    = this.newsletterStartingForm.querySelector('button[type="submit"]');
		this.newsletterStartingFormFields = this.newsletterStartingForm.querySelectorAll('input,select,textarea');

		this.overlay                   = document.querySelector('.newsletter-overlay');
		this.modal                     = document.querySelector('.newsletter-modal');
		this.closeModal                = this.modal.querySelector('.newsletter-modal-close');
		this.modalForm                 = this.modal.querySelector('form');
		this.modalFormFields           = this.modalForm.querySelectorAll('input,select,textarea');
		this.modalFormCta              = this.modalForm.querySelector('button[type="submit"]');
		this.loader                    = this.modal.querySelector('.loader');
		this.successMessage            = this.modal.querySelector('.result-message-success');
		this.errorMessage              = this.modal.querySelector('.result-message-error');

		NewsletterBox.pristineStart = new Pristine(this.newsletterStartingForm, NewsletterBox.pristineConfig, true);
		NewsletterBox.pristine      = new Pristine(this.modalForm, NewsletterBox.pristineConfig, true);

		this.attachStartingFormListeners();
		this.attachModalListeners();
	}


	attachStartingFormListeners() {
		this.newsletterStartingFormFields.forEach( (f: HTMLInputElement) => {
			f.addEventListener('change', () => {
				const valid = NewsletterBox.pristineStart.validate();

				if (valid) {
					this.newsletterStartingFormCta.removeAttribute('disabled')
				} else {
					this.newsletterStartingFormCta.setAttribute('disabled', 'disabled');
				}
			});
		});

		this.newsletterStartingForm.addEventListener('submit', (ev: Event) => { ev.preventDefault(); });

		this.newsletterStartingFormCta.addEventListener('click', () => {
			this.populateModalForm();
			this.showOverlay();
		});
	}


	attachModalListeners() {

		document.querySelectorAll('.open-newsletter-modal').forEach( (trigger: HTMLElement) => {
			trigger.addEventListener('click', () => {
				this.showOverlay();
			});
		});

		this.closeModal.addEventListener('click', (ev: MouseEvent) => {
			ev.preventDefault();
			this.hideModal();
		});

		this.overlay.addEventListener('click', (ev: MouseEvent) => {
			ev.preventDefault();
			this.hideModal();
		});

		this.modalFormFields.forEach( (f: HTMLInputElement) => {
			f.addEventListener('change', () => {
				const valid = NewsletterBox.pristine.validate();

				if (valid) {
					this.modalFormCta.removeAttribute('disabled')
				} else {
					this.modalFormCta.setAttribute('disabled', 'disabled');
				}
			});
		});

		this.modalFormCta.addEventListener('click', (ev: MouseEvent) => {
			ev.preventDefault();

			const valid = NewsletterBox.pristine.validate();

			if (valid) {
				this.hideModalForm();
				this.performCall( this.prepareData() );
			}
		});

	}


	showOverlay() {
		this.overlay.classList.add('on-top');
		document.body.classList.add('modal-open');
		gsap.to(this.overlay, {
			opacity: 1,
			onComplete: () => {
				this.showModal()
			}
		});
	}


	hideOverlay() {
		gsap.to(this.overlay, {
			opacity: 0,
			onComplete: () => {
				this.overlay.classList.remove('on-top');
				document.body.classList.remove('modal-open');
			}
		});
	}


	populateModalForm() {
		(<HTMLInputElement>this.modal.querySelector('input[type="email"]')).value = (<HTMLInputElement>this.newsletterStartingForm.querySelector('input[type="email"]')).value;
	}


	showModal() {
		this.modal.classList.add('on-top');
		gsap.to(this.modal, { opacity: 1 });
	}


	hideModal() {
		gsap.to(this.modal, {
			opacity: 0,
			onComplete: () => {
				this.modal.classList.remove('on-top');
				this.hideOverlay();
				this.modalForm.reset();
				this.showModalForm();
			}
		});
	}


	hideModalForm() {
		gsap.to(this.modalForm, {
			opacity: 0,
			height: 0
		});

		this.loader.classList.remove('hidden');
	}


	showModalForm() {
		gsap.to(this.modalForm, {
			opacity: 1,
			height: 'auto'
		});
	}


	prepareData() {
		const data: any = {};

		this.modalFormFields
			.forEach((el: HTMLInputElement | HTMLSelectElement) => {
				if (el.type == 'radio' || el.type == 'checkbox') {
					// @ts-ignore
					if (el.checked) {
						data[el.getAttribute('name')] = el.value;
					}
				} else {
					data[el.getAttribute('name')] = el.value;
				}
			});

		return data;
	}


	performCall(data: any) {
		grecaptcha.ready(() => {
			grecaptcha
				.execute( this.modalFormCta.dataset.sitekey, { action: 'submit' })
				.then((token: string) => {
					data.token = token;

					NewsletterBox.performAsyncRequest( this.modalForm.getAttribute('action'), data, (response: any) => {

						if ( response.result === 'ok' ) {
							this.newsletterStartingForm.reset();
							this.modalForm.reset();
							this.showSuccessMessage();
						} else {
							this.showErrorMessage();
						}

					}, (error: any) => {
						this.showErrorMessage();
					});
				});
		});
	}


	hideLoader() {
		this.loader.classList.add('hidden');
	}


	showSuccessMessage() {
		this.hideLoader();

		this.successMessage.classList.remove('hidden');
		gsap.to(this.successMessage, {
			opacity: 1,
			onComplete: () => {
				this.hideSuccessMessage();
			}
		});
	}


	showErrorMessage() {
		this.hideLoader();

		this.errorMessage.classList.remove('hidden');
		gsap.to(this.errorMessage, {
			opacity: 1,
			onComplete: () => {
				this.hideErrorMessage();
			}
		});
	}


	hideSuccessMessage() {
		gsap.to(this.successMessage, {
			opacity: 0,
			delay: 2,
			onComplete: () => {
				this.successMessage.classList.add('hidden');
				this.hideModal();
			}
		});
	}


	hideErrorMessage() {
		gsap.to(this.errorMessage, {
			opacity: 0,
			delay: 2,
			onComplete: () => {
				this.errorMessage.classList.add('hidden');
				this.showModalForm();
			}
		});
	}


	public static performAsyncRequest(url: string, data: any, callback: Function, errorCallback: Function ) {
		fetch(url, {
			headers: FetchHeaders.prepare(),
			method: 'POST',
			credentials: 'same-origin',
			body: JSON.stringify(data)
		})
			.then(response => response.json())
			.then((data) => { callback(data); })
			.catch((error) => { errorCallback(error) });
	}

}