import '../scss/btf/btf.scss';

import { Menu } from './components/menu/menu';
import { Header } from "./components/header/header";
import { NewsletterBox } from "./components/newsletter-box/newsletter-box";
import { SearchForm } from "./components/search-form/search-form";

document.addEventListener(
	'DOMContentLoaded',
	() => {

		if (document.querySelector('.menu-btn')) {
			new Menu();
		}

		if ( document.querySelector('header') ) {
			new Header();
		}

		if ( document.querySelector('.newsletter-box') ) {
			new NewsletterBox();
		}

		if ( document.querySelector('.search-box') ) {
			new SearchForm();
		}

		if ( document.querySelector('.multistep-form-wrapper') ) {
			import('./components/multistep-form/multistep-form').then((c) => new c.MultistepForm( document.querySelector('.multistep-form-wrapper') ));
		}

		if (document.querySelector('.page-template-home')) {
			import('./pages/home/home').then(c => new c.Home());
		} else if (document.querySelector('.page-template-project-list')) {
			import('./pages/project-list/project-list').then(c => new c.ProjectList());
		} else if (document.querySelector('.page-template-contacts')) {
			import('./pages/contacts/contacts').then((c) => new c.Contacts());
		} else {
			import('./pages/page').then((c) => {
				const p = new c.Page();
				p.pageSetup();
				p.pageReady();
			});
		}
	},
	false
);


